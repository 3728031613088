import React from "react";

import Layout from "../../../components/base/layout";
import SEO from "../../../components/base/seo";

const VisualLanguagePackage = () => (
  <Layout>
    <SEO title="Visual Language Package Addons" />
    <div className="container">
      <section className="section-checkout-form page-heading">
        <div className="checkout-product-heading">
          <h2>Visual Language Package | $1,870 CAD</h2>
        </div>
        <div className="checkout-form-details">
          <div className="checkout-desc">
            <h5 className="heading">INCLUDED:</h5>
            <ul>
              <li>45 minute visual discovery call</li>
              <li>1 Page Visual Language Guide</li>
              <li>Templates for Social Graphics</li>
              <li>2-3 Canva templates</li>
              <li>Stock Image/Graphics Collection</li>
              <li>
                Communication Templates (Pick 2)
                <ul className="ul-circle">
                  <li>Buyer Presentation Template</li>
                  <li>Annual Report Template</li>
                  <li>Sales Sheet Template</li>
                  <li>Investor Deck Template</li>
                  <li>
                    Social Media Cover Photo & Display Photo for 2 Key Channels
                  </li>
                </ul>
              </li>
            </ul>

            <p className="text-small">
              <i>
                Note:{" "}
                <strong>This package does not include logo design.</strong> If
                you are looking for a logo, please check out our design services
                here. [link to new design services page]. This package includes
                2 proofs plus a final version of each of the included items,
                with one round of revisions per proof, for a total of 2 rounds
                of revisions per item. If you require additional rounds of
                revisions or changes, they will be billed hourly at $150 CAD/hr.
              </i>
            </p>
            <p className="text-red">
              This package can only be purchased as an add-on to one of our
              other services. Please return to the services page and select this
              add-on during the checkout process.{" "}
            </p>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default VisualLanguagePackage;
